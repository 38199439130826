export interface FilterInventoryCount{
    incomplete_task_usernames__contains?:string;
    warehouse_id__eq?:number | string;
    status__in?:string;
    search?:string;
    id__eq?:string | number;
  }
  
  export interface  FilterTaskInventoryCount{
    inventory_count_id__eq:number | string;
  }
  