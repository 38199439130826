import { ListResults } from "../data";

export interface IInventoryCount {
    current_count_number:                  number;
    blocked_by_inventory_count_ids:        string[];
    expected_amount:                       number;
    received_amount:                       number;
    status:                                string;
    missing_amount:                        number;
    inventory_count_detail_file:           string;
    counts_number:                         number;
    pk_instance:                           string;
    sk:                                    string;
    external_id:                           string;
    created_by_username:                   string;
    incomplete_task_usernames:             string[];
    id:                                    string;
    modified_by_username:                  string;
    modified:                              Date;
    assigned_to_usernames:                 string[];
    location_filters:                      LocationFilters;
    blocked_amount:                        number;
    cut_date:                              Date;
    extra_amount:                          number;
    inventory_count_percentage:            number;
    warning_messages:                      string[];
    inventory_line_filters:                InventoryLineFilters;
    created:                               Date;
    customer_id:                           null;
    counted_locations:                     number;
    variant_filters:                       VariantFilters;
    source_origin:                         string;
    modified_unix_epoch:                   number;
    warehouse_id:                          string;
    created_unix_epoch:                    number;
    finish_date:                           null;
    locations_to_count:                    number;
    found_amount:                          number;
    pk:                                    string;
    audit_summary_file:                    null;
    warehouse:                             Warehouse;
    inventory_count_detail_file_download:  null;
    inventory_count_summary_file_download: null;
}

export interface InventoryLineFilters {
    id__not_in: string;
}

export interface LocationFilters {
    id__in: string;
}

export interface VariantFilters {
    id__eq: string;
}
export interface Warehouse {
    id:          string;
    external_id: string;
    name:        string;
}


export type InventoryCountListModel = ListResults<IInventoryCount>;