import { inject, Injectable } from '@angular/core';
import { EnvironmentService } from '@kanzi-apes/kanzi-utils';
import { KanziRestClientService } from '@kanzi-apes/kanzi-rest-api';
import { map, Observable } from 'rxjs';
import {
  ListResults,
  LocationsWMSFilterModel,
  LocationTypesModel,
  WarehouseListModel,
} from '@kanzi-apes/kanzi-models';
import { ILocationWmsCreate } from '../models/locations-wms/locations-create.interface';
import { getUrlPresignedFilter, ILocationWMS, IUrlPresignedResults } from '../models/locations-wms/locations.interface';
import { IPositionWms } from '../models/locations-wms/zone-position.interface';
import { IPositionCreate } from '../models/locations-wms/zone-position-create.interface';

@Injectable({
  providedIn: 'root',
})
export class KanziLocationsService {
  private httpClient = inject(KanziRestClientService);
  private envService = inject(EnvironmentService);
  private locationsWMSUrl = `${this.envService.wmsEnvironment?.apiRestModules.kongWMSAPI?.locations.apiURL}`;
  private locationsWMSPath = `${this.envService.wmsEnvironment?.apiRestModules.kongWMSAPI?.locations.apiPath}`;
  private typeLocationsUrl = `${this.envService.wmsEnvironment?.apiRestModules.kongWMSAPI?.locationTypes.apiURL}`;
  private typeLocationsPath = `${this.envService.wmsEnvironment?.apiRestModules.kongWMSAPI?.locationTypes.apiPath}`; 
  public getWarehouseList(
    filters: LocationsWMSFilterModel
  ): Observable<WarehouseListModel> {
    const url = `${this.locationsWMSUrl}${this.locationsWMSPath}`;
    return this.httpClient.get(
      url,
      filters,
      'Error al listar las bodegas',
      {},
      { tokenName: 'KanziWMSUserToken', tokenPrefix: 'COGNITO' }
    );
  }
  public getWarehouseById(filters: LocationsWMSFilterModel): Observable<WarehouseListModel> {
    const url = `${this.locationsWMSUrl}${this.locationsWMSPath}`;
    return this.httpClient.get(
      url,
      filters,
      'Error al obtener la bodega',
      {},
      { tokenName: 'KanziWMSUserToken', tokenPrefix: 'COGNITO' }
    );
  }

  public getZones(
    filters: LocationsWMSFilterModel
  ): Observable<WarehouseListModel> {
    const url = `${this.locationsWMSUrl}${this.locationsWMSPath}`;
    return this.httpClient.get(
      url,
      filters,
      'Error al listar las zonas',
      {},
      { tokenName: 'KanziWMSUserToken', tokenPrefix: 'COGNITO' }
    );
  }

  public getLocationByParent(filters: LocationsWMSFilterModel): Observable<WarehouseListModel> {
    const url = `${this.locationsWMSUrl}${this.locationsWMSPath}`;
    return this.httpClient.get(
      url,
      filters,
      'Error al obtener la locación',
      {},
      { tokenName: 'KanziWMSUserToken', tokenPrefix: 'COGNITO' }
    );
  }
  public getURLPresigned(filters: getUrlPresignedFilter): Observable<IUrlPresignedResults> {
    const url = `${this.locationsWMSUrl}${this.locationsWMSPath}/put_presigned_url`;
    return this.httpClient.get(
      url,
      filters,
      'Error al obtener la url',
      {},
      { tokenName: 'KanziWMSUserToken', tokenPrefix: 'COGNITO' }
    );
  }

  public getZonePositionById(filters: LocationsWMSFilterModel): Observable<ListResults<IPositionWms>> {
    const url = `${this.locationsWMSUrl}${this.locationsWMSPath}`;
    return this.httpClient.get(
      url,
      filters,
      'Error al obtener la locación',
      {},
      { tokenName: 'KanziWMSUserToken', tokenPrefix: 'COGNITO' }
    );
  }

  public getLocationTypes(): Observable<LocationTypesModel> {
    const url = `${this.typeLocationsUrl}${this.typeLocationsPath}/groups/types`;
    return this.httpClient.get( 
      url,
      {},
      'Error al obtener los tipos de bodegas',
      {},
      { tokenName: 'KanziWMSUserToken', tokenPrefix: 'COGNITO' }
    );
  }

  public createWarehouse(body: ILocationWmsCreate): Observable<ILocationWMS> {
    const url = `${this.locationsWMSUrl}${this.locationsWMSPath}`;
    return this.httpClient.post(
      url,
      body,
      'Error al crear la bodega.',
      {},
      { tokenName: 'KanziWMSUserToken', tokenPrefix: 'COGNITO' }
    );
  }

  public updateWarehouse(body: ILocationWmsCreate): Observable<ILocationWMS> {
    const  {id, ...rest} = body
    const url = `${this.locationsWMSUrl}${this.locationsWMSPath}/${id}`;
    return this.httpClient.patch(
      url,
      rest,
      {},
      'Error al crear la bodega.',
      {},
      { tokenName: 'KanziWMSUserToken', tokenPrefix: 'COGNITO' }
    );
  }

  public createZonePosition(body: IPositionCreate): Observable<IPositionWms> {
    const url = `${this.locationsWMSUrl}${this.locationsWMSPath}`;
    return this.httpClient.post(
      url,
      body,
      'Error al crear la locación.',
      {},
      { tokenName: 'KanziWMSUserToken', tokenPrefix: 'COGNITO' }
    );
  }

  public updateZonePosition(body: IPositionCreate): Observable<IPositionWms> {
    const  {id, ...rest} = body
    const url = `${this.locationsWMSUrl}${this.locationsWMSPath}/${id}`;
    return this.httpClient.patch(
      url,
      rest,
      {},
      'Error al actualizar la locación.',
      {},
      { tokenName: 'KanziWMSUserToken', tokenPrefix: 'COGNITO' }
    );
  }
}
