import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { KanziPrimeUiModule } from '@kanzi-apes/kanzi-prime-ui';
import { TableModule } from 'primeng/table';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { JoyrideModule } from 'ngx-joyride';
/** Components */
import {
  KanziDynamicTableComponent,
  KanziHeaderSectionComponent,
  KanziDetailDataComponent,
  KanziBadgeStatusComponent,
  PropertiesTableComponent,
  KanziDynamicQuestionComponent,
  // KanziDynamicFormComponent,
  KanziDynamicComponentComponent,
  KanziDynamicSimpleTableComponent,
  KanziPackageListComponent,
  KanziCountWidgetComponent,
  MainMenuComponent,
  MenuRigthComponent,
  MenuSettingsComponent,
  CustomerSelectorComponent,
  KanziSkuLineCreateComponent,
  KanziDynamicTreeTableComponent,
  KanziUiTopBarComponent,
  KanziUiMainSidebarComponent,
  KanziUiMenuUserProfileComponent,
  KanziUiFooterComponent,
  KanziUiBreadcrumbComponent,
  KanziUiMainMenuComponent,
  KanziUiMenuItemComponent,
  KanziUiMainCardComponent,
  KanziUIWarehouseRootSelectorComponent,
  KanziUiDetailSidebarComponent,
} from './components';
import { EmptyModuleDisplayComponent } from './components/empty-module/empty-module-display.component';
import { KanziTopBarComponent } from './components/kanzi-top-bar/kanzi-top-bar.component';
import { KanziLangTopSelectComponent } from './components/kanzi-lang-top-select/kanzi-lang-top-select.component';
import { KanziMainSidebarComponent } from './components/kanzi-main-sidebar/kanzi-main-sidebar.component';
import { KanziRightSidebarComponent } from './components/kanzi-right-sidebar/kanzi-right-sidebar.component';
import { KanziProgressBarComponent } from './components/kanzi-progress-bar/kanzi-progress-bar.component';
import { KanziTotalsWidgetComponent } from './components/kanzi-totals-widget/kanzi-totals-widget.component';
import { KanziTotalsComparativeWidgetComponent } from './components/kanzi-totals-comparative-widget/kanzi-totals-comparative-widget.component';
import { KanziTextContentWidgetComponent } from './components/kanzi-text-content-widget/kanzi-text-content-widget.component';
import { KanziSkuLineFromTextComponent } from './components/kanzi-sku-line-from-text/kanzi-sku-line-from-text.component';
import { KanziCountCardComponent } from './components/kanzi-count-card/kanzi-count-card.component';
// import { KanziCrudTableComponent } from './components/kanzi-crud-table/kanzi-crud-table.component';
import { KanziRefillEditTableComponent } from './components/kanzi-refill-edit-table/kanzi-refill-edit-table.component';
// import { KanziAssingsEditTableComponent } from './components/kanzi-assings-edit-table/kanzi-assings-edit-table.component';
// import { KanziAssingsFormComponent } from './components/kanzi-assings-form/kanzi-assings-form.component';
import { KanziDynamicNewTableComponent } from './components/kanzi-dynamic-new-table/kanzi-dynamic-new-table.component';
import { RouterModule } from '@angular/router';
import { KanziFooterComponent } from './components/kanzi-footer/kanzi-footer.component';
import { KanziMenuItemComponent } from './components/kanzi-menu-item/kanzi-menu-item.component';
/** Directives */
import { StatusStyleDirective } from './directives/status-style.directive';
import { BageStatusStyleDirective } from './directives/bage-status-style.directive';
import { AdDirective } from './directives/add.directive';
import { MenuHideAuthDirective } from './directives/auth/menu-hide-auth.directive';
import { MenuHideRigthDirective } from './directives/auth/menu-hide-rigth.directive';
import { ActionStyleDirective } from './directives/action-style/action-style.directive';
import { KanziTemplate } from './directives/kanzi-template/kanzi-template.directive';
/** Pipes */
import {
  KanziAppPipes,
  GenderPipe,
  NumberFormatPipe,
  StateWorkOrderPipe,
  GTINStructurePipe,
  SGTINFilterPipe,
  StatusReportAuditsPipe,
  StatusReportLinesAuditsPipe,
  TransferStatusPipe,
  PrintOrderStatusPipe,
  KanziNameAbrv,
  kanziNameOrderPipe,
  ReporterSourcePipe,
  kanziDisplayNameOrder,
  kanziLocationsNameOrder,
  booleansPipes,
  DecimalFormatPipe,
} from './pipes/app-pipes.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { KanziFileUploadComponent } from './components/kanzi-file-upload/kanzi-file-upload.component';
import { KanziTimePickerComponent } from './components/kanzi-time-picker/kanzi-time-picker.component';
import { KanziUsersAssignComponent } from './components/kanzi-users-assign/kanzi-users-assign.component';
import { KanziUserAssignModalComponent } from './components/kanzi-user-assign-modal/kanzi-user-assign-modal.component';
import { MWKOrderStatusStyleDirective } from './directives/mwk-status-style/mwk-status-style.directive';
import { BudgetStatusStyleDirective } from './directives/budget-status-style/budget-status-style.directive';
import { KanziDynamicSkuLineCreateComponent } from './components/kanzi-dynamic-sku-line-create/kanzi-dynamic-sku-line-create.component';
import { KanziDynamicTableServicesComponent } from './components/kanzi-dynamic-table-services/kanzi-dynamic-table-services.component';
import { KanziBreadcrumbComponent } from './components/kanzi-breadcrumb/kanzi-breadcrumb.component';
import { KanziItemTypesComponent } from './components/kanzi-item-types/kanzi-item-types.component';
import { KanziTablePropertiesComponent } from './components/kanzi-table-properties/kanzi-table-properties.component';
import { KanziStatusSeverityPipe } from './pipes/kanzi-status.pipe';
import { KanziUiLineCreateComponent } from './components/kanzi-ui-line-create/kanzi-ui-line-create.component';
@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    KanziPrimeUiModule,
    TableModule,
    RouterModule,
    FontAwesomeModule,
    FormsModule,
    JoyrideModule.forChild(),
    KanziStatusSeverityPipe,
  ],
  declarations: [
    kanziDisplayNameOrder,
    kanziLocationsNameOrder,
    KanziAppPipes,
    DecimalFormatPipe,
    booleansPipes,
    GenderPipe,
    NumberFormatPipe,
    kanziNameOrderPipe,
    StateWorkOrderPipe,
    GTINStructurePipe,
    SGTINFilterPipe,
    StatusReportAuditsPipe,
    StatusReportLinesAuditsPipe,
    TransferStatusPipe,
    PrintOrderStatusPipe,
    ReporterSourcePipe,
    EmptyModuleDisplayComponent,
    KanziDynamicTableComponent,
    StatusStyleDirective,
    KanziNameAbrv,
    KanziHeaderSectionComponent,
    KanziDetailDataComponent,
    KanziBadgeStatusComponent,
    BageStatusStyleDirective,
    PropertiesTableComponent,
    KanziDynamicQuestionComponent,
    // KanziDynamicFormComponent,
    AdDirective,
    KanziDynamicComponentComponent,
    SafePipe,
    KanziDynamicSimpleTableComponent,
    KanziPackageListComponent,
    KanziCountWidgetComponent,
    MainMenuComponent,
    MenuRigthComponent,
    MenuSettingsComponent,
    MenuHideAuthDirective,
    MenuHideRigthDirective,
    KanziTopBarComponent,
    CustomerSelectorComponent,
    KanziLangTopSelectComponent,
    KanziMainSidebarComponent,
    KanziRightSidebarComponent,
    KanziSkuLineCreateComponent,
    KanziProgressBarComponent,
    ActionStyleDirective,
    KanziTotalsWidgetComponent,
    KanziTotalsComparativeWidgetComponent,
    KanziTextContentWidgetComponent,
    KanziSkuLineFromTextComponent,
    KanziDynamicTreeTableComponent,
    KanziCountCardComponent,
    KanziRefillEditTableComponent,
    KanziDynamicNewTableComponent,
    KanziFooterComponent,
    KanziMenuItemComponent,
    KanziFileUploadComponent,
    KanziTimePickerComponent,
    KanziUsersAssignComponent,
    KanziUserAssignModalComponent,
    MWKOrderStatusStyleDirective,
    BudgetStatusStyleDirective,
    KanziDynamicSkuLineCreateComponent,
    KanziDynamicTableServicesComponent,
    KanziBreadcrumbComponent,
    KanziItemTypesComponent,
    KanziTablePropertiesComponent,
    KanziUiTopBarComponent,
    KanziUiMainSidebarComponent,
    KanziUiMenuUserProfileComponent,
    KanziUiFooterComponent,
    KanziUiBreadcrumbComponent,
    KanziUiMainMenuComponent,
    KanziUiMenuItemComponent,
    KanziUiMainCardComponent,
    KanziUIWarehouseRootSelectorComponent,
    KanziTemplate,
    KanziUiDetailSidebarComponent,
    KanziUiLineCreateComponent
  ],
  exports: [
    kanziDisplayNameOrder,
    kanziLocationsNameOrder,
    KanziAppPipes,
    DecimalFormatPipe,
    GenderPipe,
    booleansPipes,
    NumberFormatPipe,
    kanziNameOrderPipe,
    StateWorkOrderPipe,
    GTINStructurePipe,
    SGTINFilterPipe,
    StatusReportAuditsPipe,
    StatusReportLinesAuditsPipe,
    TransferStatusPipe,
    PrintOrderStatusPipe,
    ReporterSourcePipe,
    EmptyModuleDisplayComponent,
    KanziDynamicTableComponent,
    StatusStyleDirective,
    KanziNameAbrv,
    KanziHeaderSectionComponent,
    KanziDetailDataComponent,
    KanziBadgeStatusComponent,
    BageStatusStyleDirective,
    PropertiesTableComponent,
    KanziDynamicQuestionComponent,
    // KanziDynamicFormComponent,
    AdDirective,
    KanziDynamicComponentComponent,
    SafePipe,
    KanziDynamicSimpleTableComponent,
    KanziPackageListComponent,
    KanziCountWidgetComponent,
    MainMenuComponent,
    MenuRigthComponent,
    MenuSettingsComponent,
    MenuHideAuthDirective,
    MenuHideRigthDirective,
    KanziTopBarComponent,
    CustomerSelectorComponent,
    KanziLangTopSelectComponent,
    KanziMainSidebarComponent,
    KanziRightSidebarComponent,
    KanziSkuLineCreateComponent,
    KanziProgressBarComponent,
    ActionStyleDirective,
    KanziTotalsWidgetComponent,
    KanziTotalsComparativeWidgetComponent,
    KanziTextContentWidgetComponent,
    KanziSkuLineFromTextComponent,
    KanziDynamicTreeTableComponent,
    KanziCountCardComponent,
    // KanziCrudTableComponent,
    KanziRefillEditTableComponent,
    // KanziAssingsEditTableComponent,
    // KanziAssingsFormComponent,
    KanziDynamicNewTableComponent,
    KanziFooterComponent,
    KanziFileUploadComponent,
    KanziTimePickerComponent,
    KanziUsersAssignComponent,
    KanziUserAssignModalComponent,
    MWKOrderStatusStyleDirective,
    BudgetStatusStyleDirective,
    KanziDynamicSkuLineCreateComponent,
    KanziDynamicTableServicesComponent,
    KanziBreadcrumbComponent,
    KanziItemTypesComponent,
    KanziTablePropertiesComponent,
    KanziUiTopBarComponent,
    KanziUiMainSidebarComponent,
    KanziUiMenuUserProfileComponent,
    KanziUiFooterComponent,
    KanziUiBreadcrumbComponent,
    KanziUiMainMenuComponent,
    KanziUiMainCardComponent,
    KanziUIWarehouseRootSelectorComponent,
    KanziTemplate,
    KanziUiDetailSidebarComponent,
    KanziUiLineCreateComponent
  ],
  providers: [{ provide: Window, useValue: window }],
})
export class KanziUiModule {}
